import React, { useEffect, useState } from 'react';
import { ButtonGroup, Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getPackagesAction } from '../../../redux/actions/getPackages';
import { paymentAction } from '../../../redux/actions/payment';
import { paymentStatusAction } from '../../../redux/actions/payamentStatus';
import PaymentPage from './paymentPage';
import { PlanCard } from './planCard';

const PlansPage = (props) => {
  const dispatch = useDispatch();
  const [isYearly, setIsYearly] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    dispatch(getPackagesAction());
  }, []);

  let URLParams = props?.location?.search;
  URLParams = URLParams?.split('&');

  const payment_id = URLParams[0]?.split('=');
  const resourcePath = URLParams[1]?.split('=');

  if (payment_id && resourcePath) {
    const formData = new FormData();

    formData.append('payment_id', payment_id[1]);
    formData.append('resourcePath', resourcePath[1]);

    dispatch(paymentStatusAction(formData));
  }

  const packages = useSelector((state) => state.packages);
  const payment = useSelector((state) => state.payment);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handlePackageYearlySelected = () => {
    setIsYearly(isYearly === false ? true : false);
  };

  const handlePayment = (id) => {
    const formData = new FormData();

    formData.append('package_id', id);
    formData.append(
      'payment_frequency',
      isYearly === true ? 'yearly' : 'monthly'
    );

    dispatch(paymentAction(formData));
    handleShow();
  };

  return (
    <>
      <div className='plans-wrapper'>
        <div className='plans__filters-area'>
          <button
            className={`plans-filter-button ${
              isYearly === true ? '' : 'plans-filter-button--active'
            }`}
            onClick={() => handlePackageYearlySelected()}
          >
            Monthly
          </button>
          <button
            className={`plans-filter-button ${
              isYearly === true ? 'plans-filter-button--active' : ''
            }`}
            onClick={() => handlePackageYearlySelected()}
          >
            Yearly
          </button>
        </div>
        <div className='row'>
          {packages?.data?.entityList?.map((card) => {
            const active = card.IsFeatured === 1;
            return (
              <div className='col-lg-4 col-sm-6' key={card.Identifier}>
                <PlanCard
                  card={card}
                  active={active}
                  isYearly={isYearly}
                  handlePayment={handlePayment}
                />
              </div>
            );
          })}
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <PaymentPage handleClose={handleClose} paymentData={payment} />
      </Modal>
    </>
  );
};

export default PlansPage;
