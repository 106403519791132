import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import Beneficiaries from "./modules/Beneficiaries";
import BeneficiariesForm from "./modules/Beneficiaries/BeneficiariesForm";
import EditBeneficiary from "./modules/Beneficiaries/EditBeneficiary";
import { DashboardPage } from "./pages/DashboardPage";
import UserProfile from "./modules/UserProfile/UserProfilePage";
import Media from "./modules/Media";
import Plans from "./modules/Plans";
import Cards from "./modules/Cards";
import historyList from "./modules/history/historyList";
import ChangePassword from "./modules/UserProfile/ChangePassword";

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/user-profile" component={UserProfile} />
        <ContentRoute path="/beneficiaries" component={Beneficiaries} />
        <ContentRoute path="/media/library" component={Media} />
        <ContentRoute path="/plans" component={Plans} />
        <ContentRoute path="/beneficiary-form" component={BeneficiariesForm} />
        <ContentRoute path="/beneficiary/:id" component={EditBeneficiary} />
        <ContentRoute path="/cards" component={Cards} />
        <ContentRoute path="/history" component={historyList} />
        <ContentRoute path="/change-pass" component={ChangePassword} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
