//AUTH
export const START_LOGIN_REQUEST = "START_LOGIN_REQUEST";
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS";
export const LOGIN_REQUEST_FAILED = "LOGIN_REQUEST_FAILED";

export const START_REGISTER_REQUEST = "START_REGISTER_REQUEST";
export const REGISTER_REQUEST_SUCCESS = "REGISTER_REQUEST_SUCCESS";
export const REGISTER_REQUEST_FAILED = "REGISTER_REQUEST_FAILED";

export const START_MAIL_CONFIRMATION_REQUEST =
  "START_MAIL_CONFIRMATION_REQUEST";
export const MAIL_CONFIRMATION_REQUEST_SUCCESS =
  "MAIL_CONFIRMATION_REQUEST_SUCCESS";
export const MAIL_CONFIRMATION_REQUEST_FAILED =
  "MAIL_CONFIRMATION_REQUEST_FAILED";

export const START_FORGETPASSWORD_REQUEST = "START_FORGETPASSWORD_REQUEST";
export const FORGETPASSWORD_REQUEST_SUCCESS = "FORGETPASSWORD_REQUEST_SUCCESS";
export const FORGETPASSWORD_REQUEST_FAILED = "FORGETPASSWORD_REQUEST_FAILED";

export const START_CONFIRM_FORGETTOKEN_REQUEST =
  "START_CONFIRM_FORGETTOKEN_REQUEST";
export const CONFIRM_FORGETTOKEN_REQUEST_SUCCESS =
  "CONFIRM_FORGETTOKEN_REQUEST_SUCCESS";
export const CONFIRM_FORGETTOKEN_REQUEST_FAILED =
  "CONFIRM_FORGETTOKEN_REQUEST_FAILED";

export const START_RESET_PASSWORD_REQUEST = "START_RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_REQUEST_SUCCESS = "RESET_PASSWORD_REQUEST_SUCCESS";
export const RESET_PASSWORD_REQUEST_FAILED = "RESET_PASSWORD_REQUEST_FAILED";
//

export const START_EDIT_PROFILE_REQUEST = "START_EDIT_PROFILE_REQUEST";
export const EDIT_PROFILE_REQUEST_SUCCESS = "EDIT_PROFILE_REQUEST_SUCCESS";
export const EDIT_PROFILE_REQUEST_FAILED = "EDIT_PROFILE_REQUEST_FAILED";

export const START_COUNTRIES_REQUEST = "START_COUNTRIES_REQUEST";
export const COUNTRIES_REQUEST_SUCCESS = "COUNTRIES_REQUEST_SUCCESS";
export const COUNTRIES_REQUEST_FAILED = "COUNTRIES_REQUEST_FAILED";

export const START_CITIES_REQUEST = "START_CITIES_REQUEST";
export const CITIES_REQUEST_SUCCESS = "CITIES_REQUEST_SUCCESS";
export const CITIES_REQUEST_FAILED = "CITIES_REQUEST_FAILED";

export const START_PACKAGES_REQUEST = "START_PACKAGES_REQUEST";
export const PACKAGES_REQUEST_SUCCESS = "PACKAGES_REQUEST_SUCCESS";
export const PACKAGES_REQUEST_FAILED = "PACKAGES_REQUEST_FAILED";

//BENEFECIARIES
export const START_RELATIONS_REQUEST = "START_RELATIONS_REQUEST";
export const RELATIONS_REQUEST_SUCCESS = "RELATIONS_REQUEST_SUCCESS";
export const RELATIONS_REQUEST_FAILED = "RELATIONS_REQUEST_FAILED";

export const START_BENEFECIARIES_REQUEST = "START_BENEFECIARIES_REQUEST";
export const BENEFECIARIES_REQUEST_SUCCESS = "BENEFECIARIES_REQUEST_SUCCESS";
export const BENEFECIARIES_REQUEST_FAILED = "BENEFECIARIES_REQUEST_FAILED";

export const START_ADD_BENEFECIARY_REQUEST = "START_ADD_BENEFECIARY_REQUEST";
export const ADD_BENEFECIARY_REQUEST_SUCCESS =
  "ADD_BENEFECIARY_REQUEST_SUCCESS";
export const ADD_BENEFECIARY_REQUEST_FAILED = "ADD_BENEFECIARY_REQUEST_FAILED";

export const START_EDIT_BENEFECIARY_REQUEST = "START_EDIT_BENEFECIARY_REQUEST";
export const EDIT_BENEFECIARY_REQUEST_SUCCESS =
  "EDIT_BENEFECIARY_REQUEST_SUCCESS";
export const EDIT_BENEFECIARY_REQUEST_FAILED =
  "EDIT_BENEFECIARY_REQUEST_FAILED";

export const START_DELETE_BENEFECIARY_REQUEST =
  "START_DELETE_BENEFECIARY_REQUEST";
export const DELETE_BENEFECIARY_REQUEST_SUCCESS =
  "DELETE_BENEFECIARY_REQUEST_SUCCESS";
export const DELETE_BENEFECIARY_REQUEST_FAILED =
  "DELETE_BENEFECIARY_REQUEST_FAILED";
//

//MEDIA
export const START_GET_MEDIA_REQUEST = "START_GET_MEDIA_REQUEST";
export const GET_MEDIA_REQUEST_SUCCESS = "GET_MEDIA_REQUEST_SUCCESS";
export const GET_MEDIA_REQUEST_FAILED = "GET_MEDIA_REQUEST_FAILED";

export const START_ADD_MEDIA_REQUEST = "START_ADD_MEDIA_REQUEST";
export const ADD_MEDIA_REQUEST_SUCCESS = "ADD_MEDIA_REQUEST_SUCCESS";
export const ADD_MEDIA_REQUEST_FAILED = "ADD_MEDIA_REQUEST_FAILED";

export const START_DELETE_MEDIA_REQUEST = "START_DELETE_MEDIA_REQUEST";
export const DELETE_MEDIA_REQUEST_SUCCESS = "DELETE_MEDIA_REQUEST_SUCCESS";
export const DELETE_MEDIA_REQUEST_FAILED = "DELETE_MEDIA_REQUEST_FAILED";
//

//PAYMENT
export const START_PAYMENT_REQUEST = "START_PAYMENT_REQUEST";
export const PAYMENT_REQUEST_SUCCESS = "PAYMENT_REQUEST_SUCCESS";
export const PAYMENT_REQUEST_FAILED = "PAYMENT_REQUEST_FAILED";

export const START_PAYMENT_STATUS_REQUEST = "START_PAYMENT_STATUS_REQUEST";
export const PAYMENT_STATUS_REQUEST_SUCCESS = "PAYMENT_STATUS_REQUEST_SUCCESS";
export const PAYMENT_STATUS_REQUEST_FAILED = "PAYMENT_STATUS_REQUEST_FAILED";

// Stripe status after payment
export const STRIPE_START_PAYMENT_STATUS_REQUEST =
  "STRIPE_START_PAYMENT_STATUS_REQUEST";
export const STRIPE_PAYMENT_STATUS_REQUEST_SUCCESS =
  "STRIPE_PAYMENT_STATUS_REQUEST_SUCCESS";
export const STRIPE_PAYMENT_STATUS_REQUEST_FAILED =
  "STRIPE_PAYMENT_STATUS_REQUEST_FAILED";

//SUBSCRIBTION INFO
export const START_SUBSCRIBTION_INFO_REQUEST =
  "START_SUBSCRIBTION_INFO_REQUEST";
export const SUBSCRIBTION_INFO_REQUEST_SUCCESS =
  "SUBSCRIBTION_INFO_REQUEST_SUCCESS";
export const SUBSCRIBTION_INFO_REQUEST_FAILED =
  "SUBSCRIBTION_INFO_REQUEST_FAILED";

//SEND CARD
export const START_CARD_REQUEST = "START_CARD_REQUEST";
export const CARD_REQUEST_SUCCESS = "CARD_REQUEST_SUCCESS";
export const CARD_REQUEST_FAILED = "CARD_REQUEST_FAILED";

//LIST CARD
export const START_LIST_CARD_REQUEST = "START_LIST_CARD_REQUEST";
export const LIST_CARD_REQUEST_SUCCESS = "LIST_CARD_REQUEST_SUCCESS";
export const LIST_CARD_REQUEST_FAILED = "LIST_CARD_REQUEST_FAILED";

export const START_PREVIEW_CARD_REQUEST = "START_PREVIEW_CARD_REQUEST";
export const PREVIEW_CARD_REQUEST_SUCCESS = "PREVIEW_CARD_REQUEST_SUCCESS";
export const PREVIEW_CARD_REQUEST_FAILED = "PREVIEW_CARD_REQUEST_FAILED";

export const START_DELETE_CARD_REQUEST = "START_DELETE_CARD_REQUEST";
export const DELETE_CARD_REQUEST_SUCCESS = "DELETE_CARD_REQUEST_SUCCESS";
export const DELETE_CARD_REQUEST_FAILED = "DELETE_CARD_REQUEST_FAILED";

export const START_CONFIRM_DEACESED_REQUEST = "START_CONFIRM_DEACESED_REQUEST";
export const CONFIRM_DEACESED_REQUEST_SUCCESS =
  "CONFIRM_DEACESED_REQUEST_SUCCESS";
export const CONFIRM_DEACESED_REQUEST_FAILED =
  " CONFIRM_DEACESED_REQUEST_FAILED";

export const START_CHANGE_PASSWORD_REQUEST = "START_CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_REQUEST_SUCCESS =
  "CHANGE_PASSWORD_REQUEST_SUCCESS";
export const CHANGE_PASSWORD_REQUEST_FAILED = "CHANGE_PASSWORD_REQUEST_FAILED";

export const START_GET_NOK_REQUEST = "START_GET_NOK_REQUEST";
export const GET_NOK_REQUEST_SUCCESS = "GET_NOK_REQUEST_SUCCESS";
export const GET_NOK_REQUEST_FAILED = "GET_NOK_REQUEST_FAILED";
