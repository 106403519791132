import { takeLatest, call, put } from "redux-saga/effects";
import axios from "../../API/axiosClient";
import {
  START_LIST_CARD_REQUEST,
  LIST_CARD_REQUEST_SUCCESS,
  LIST_CARD_REQUEST_FAILED,
} from "../actions/actionTypes";

export function* listCardsWatcher() {
  yield takeLatest(START_LIST_CARD_REQUEST, listCardsWorker);
}

function listCards(payload) {
  return axios.get("api/list-ecards", payload);
}

function* listCardsWorker() {
  try {
    const response = yield call(listCards);
    const data = response.data;

    yield put({ type: LIST_CARD_REQUEST_SUCCESS, data });
  } catch (error) {
    yield put({ type: LIST_CARD_REQUEST_FAILED, error });
  }
}
