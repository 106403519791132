import { takeLatest, call, put } from "redux-saga/effects";
import axios from "../../../API/axiosClient";
import {
  START_DELETE_MEDIA_REQUEST,
  DELETE_MEDIA_REQUEST_SUCCESS,
  DELETE_MEDIA_REQUEST_FAILED,
  START_GET_MEDIA_REQUEST,
} from "../../actions/actionTypes";

export function* deleteMediaWatcher() {
  yield takeLatest(START_DELETE_MEDIA_REQUEST, deleteMediaWorker);
}

function deleteMedia(payload) {
  return axios.delete(`api/uploads/delete`, {
    data: { Identifier: payload },
  });
}

function* deleteMediaWorker(action) {
  try {
    const response = yield call(deleteMedia, action.payload);
    const data = response.data;

    yield put({ type: DELETE_MEDIA_REQUEST_SUCCESS, data });

    if (data?.code === 200) {
      yield put({ type: START_GET_MEDIA_REQUEST });
    }
  } catch (error) {
    yield put({ type: DELETE_MEDIA_REQUEST_FAILED, error });
  }
}
