import { takeLatest, call, put } from "redux-saga/effects";
import axios from "../../API/axiosClient";
import {
  START_CONFIRM_DEACESED_REQUEST,
  CONFIRM_DEACESED_REQUEST_SUCCESS,
  CONFIRM_DEACESED_REQUEST_FAILED,
} from "../actions/actionTypes";
import swal from "sweetalert";

export function* confirmDeacesedWatcher() {
  yield takeLatest(START_CONFIRM_DEACESED_REQUEST, confirmDeacesedWorker);
}

function confirmDeacesed(payload) {
  return axios.post("api/confirm-deceased", payload);
}

function* confirmDeacesedWorker(action) {
  try {
    const response = yield call(confirmDeacesed, action.payload);
    const data = response.data;
    if (data.entityList) {
      swal("", "Confirmed", "success");
    }

    yield put({
      type: CONFIRM_DEACESED_REQUEST_SUCCESS,
      data: data.entityList,
    });
  } catch (error) {
    yield put({ type: CONFIRM_DEACESED_REQUEST_FAILED, error });
  }
}
