import React from 'react';

{
  /* <p className={card.Name === 'Gold' ? 'my-2 ' : 'text-muted'}>

</p> */
}

// <div className=''>

// <h1 className='my-4 mx-2'>
//   {}
// </h1>
// <span className={card.Name === 'Gold' ? '' : 'text-muted'}>
//   /{isYearly === true ? 'Year' : 'Month'}
// </span>
// </div>

{
  /* <h1 className=''>{card.Name}</h1> */
}

export const PlanCard = ({ card, isYearly, handlePayment, active }) => {
  if (!active) {
    return (
      <div className='plan-card'>
        <div className='plan-card__body'>
          <div className='plan-card__body__info'>
            <div className='plan-type'>
              <div className='plan-type-name'>{card.Name}</div>
              <div className='plan-type-wyshes'>{card.Cards} Wyshes</div>
            </div>
            <p className='plan-fees'>
              £{isYearly === true ? card.PriceYearly : card.PriceMonthly}
              <span>{isYearly === true ? ' /Yearly' : ' /Monthly'}</span>
            </p>
          </div>
          <div className='plan-card__body__cta'>
            <button
              className='plan-card__body__cta__btn'
              onClick={() => handlePayment(card.Identifier)}
            >
              Select
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='plan-card plan-card--active'>
      <div className='plan-card__body'>
        <div className='plan-card__title'>Your Plan</div>
        <div className='plan-card__body__info'>
          <div className='plan-type'>
            <div className='plan-type-name'>{card.Name}</div>
            <div className='plan-type-wyshes'>{card.Cards} Wyshes</div>
          </div>
          <p className='plan-fees'>
            £{isYearly === true ? card.PriceYearly : card.PriceMonthly}
            <span>{isYearly === true ? ' /Yearly' : ' /Monthly'}</span>
          </p>
        </div>
      </div>
    </div>
  );
};
