import { takeLatest, call, put } from "redux-saga/effects";
import axios from "../../API/axiosClient";
import {
  START_DELETE_CARD_REQUEST,
  DELETE_CARD_REQUEST_SUCCESS,
  DELETE_CARD_REQUEST_FAILED,
  START_LIST_CARD_REQUEST,
} from "../actions/actionTypes";

export function* deleteECardWatcher() {
  yield takeLatest(START_DELETE_CARD_REQUEST, deleteECardWorker);
}

function deleteECard(payload) {
  return axios.delete("api/delete-ecard", {
    data: payload,
  });
}

function* deleteECardWorker(action) {
  try {
    const response = yield call(deleteECard, action.payload);
    const data = response.data;

    yield put({ type: DELETE_CARD_REQUEST_SUCCESS, data });

    if (data) {
      yield put({ type: START_LIST_CARD_REQUEST });
    }
  } catch (error) {
    yield put({ type: DELETE_CARD_REQUEST_FAILED, error });
  }
}
