import { takeLatest, call, put } from "redux-saga/effects";
import axios from "../../API/axiosClient";
import {
  START_GET_NOK_REQUEST,
  GET_NOK_REQUEST_SUCCESS,
  GET_NOK_REQUEST_FAILED,
} from "../actions/actionTypes";

export function* NOKusersWatcher() {
  yield takeLatest(START_GET_NOK_REQUEST, NOKusersWorker);
}

function NOKUsers() {
  return axios.get("api/nok_users");
}

function* NOKusersWorker() {
  try {
    const response = yield call(NOKUsers);
    const data = response.data;

    yield put({ type: GET_NOK_REQUEST_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_NOK_REQUEST_FAILED, error });
  }
}
