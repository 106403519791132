import { takeLatest, call, put } from "redux-saga/effects";
import axios from "../../API/axiosClient";
import {
  START_PAYMENT_REQUEST,
  PAYMENT_REQUEST_SUCCESS,
  PAYMENT_REQUEST_FAILED,
} from "../actions/actionTypes";

export function* paymentWatcher() {
  yield takeLatest(START_PAYMENT_REQUEST, paymentWorker);
}

function payment(payload) {
  return axios.post("api/stripe-token", payload);
}

function* paymentWorker(action) {
  try {
    const response = yield call(payment, action.payload);
    const data = response.data;

    yield put({ type: PAYMENT_REQUEST_SUCCESS, data });
  } catch (error) {
    yield put({ type: PAYMENT_REQUEST_FAILED, error });
  }
}
