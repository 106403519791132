import axios from "axios";
import { getLocalState, removeLocalState } from "../helpers/localStorage";

axios.defaults.baseURL = process.env.REACT_APP_API_BASEURL;

// Add axios interceptor to inject the token to requests
axios.interceptors.request.use((request) => {
  request.headers["Content-Type"] = "application/json";

  const accessToken = getLocalState("@token");
  if (accessToken) {
    request.headers["Authorization"] = `Bearer ${accessToken}`;
  }

  return request;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error?.response?.status === 401 &&
      !error?.request.responseURL?.includes("api/confirm-forget-token") &&
      !error?.request.responseURL?.includes("api/login")
    ) {
      removeLocalState("@token");
      removeLocalState("@user");
      window.location = "/";
    } else {
      return error?.response;
    }
  }
);

export default axios;
