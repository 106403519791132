import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { injectIntl } from "react-intl";
import * as Yup from "yup";
import { getLocalState } from "../../../helpers/localStorage";

import CardEditor from "./CardEditor";
import CardPreview from "./CardPreview";
import MediaLibrary from "../Media/MediaLibrary";
import { getBeneficiariesAction } from "../../../redux/actions/Beneficiaries/getBeneficiaries";
import { sendCardAction } from "../../../redux/actions/sendCard";
import { Link, useHistory } from "react-router-dom";
import toast from "react-hot-toast";

const initialValues = {
  subject: "",
  selectDate: false,
  date: "",
  specificDate: "",
};

const afterDeathValues = [
  {
    id: 0,
    value: "Immediate After Death",
  },
  {
    id: 1,
    value: "1 month",
  },
  {
    id: 2,
    value: "2 months",
  },
  {
    id: 3,
    value: "3 months",
  },
  {
    id: 4,
    value: "4 months",
  },
  {
    id: 5,
    value: "5 months",
  },
];

const Cards = (props) => {
  const { intl } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedDateValue, setSelectedDateValue] = useState("");
  const [previewItem, setPreviewItem] = useState("");
  const [editorContent, setContentEditor] = useState("");
  const [selectedBeneficiary, setSelectedBeneficiary] = useState("");
  const [deathDate, setDeathDate] = useState("");
  const [isPreview, setIsPreview] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    dispatch(getBeneficiariesAction());
  }, []);

  const beneficiaries = useSelector((state) => state.beneficiaries);
  const sendCard = useSelector((state) => state.sendCard);
  const history = useHistory();

  useEffect(() => {
    if (sendCard?.data?.code === 200) {
      window.location.href = "/history";
    }
  }, [sendCard]);

  const cardSchema = Yup.object().shape({
    subject: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(500, "Maximum 500 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: cardSchema,
    onSubmit: (values, { setSubmitting }) => {
      if (!selectedBeneficiary || selectedBeneficiary === "") {
        toast.error("Please select beneficiary");
        disableLoading();
        setSubmitting(false);
        return;
      }

      if (!values.date && !values.specificDate && !deathDate) {
        toast.error("Please set the date");
        disableLoading();
        setSubmitting(false);
        return;
      }

      if (!editorContent) {
        toast.error("Please type you message");
        disableLoading();
        setSubmitting(false);
        return;
      }

      if (!previewItem || previewItem === "") {
        toast.error("Please select media file/s");
        disableLoading();
        setSubmitting(false);
        return;
      }

      setSubmitting(true);

      enableLoading();

      const formData = new FormData();

      let specificDate = values.specificDate.split("T");
      let specificDateFormated = specificDate[0];

      formData.append("BeneficiaryID", selectedBeneficiary);
      formData.append("MediaLink", previewItem.realPath);
      formData.append("MediaID", previewItem._id);
      formData.append("Subject", values.subject);
      formData.append("Message", editorContent);
      formData.append(
        "SendingDateType",
        selectedDateValue === "deathDate" ? 2 : 1
      );

      if (selectedDateValue === "deathDate")
        formData.append("AfterDeathDuration", deathDate);

      if (selectedDateValue === "specificDate")
        formData.append("SendingDate", specificDateFormated);

      if (isPreview === true) formData.append("IsPreview", 1);

      if (isPreview === true) {
        dispatch(sendCardAction(formData, 1));
        handleShow();
      } else {
      }
      dispatch(sendCardAction(formData));

      setTimeout(() => {
        disableLoading();
        setSubmitting(false);
      }, 1500);
    },
  });

  const handleEditorChange = (content) => {
    setContentEditor(content);
  };

  const handleBeneficiaryChange = (e) => {
    setSelectedBeneficiary(e.target.value);
  };

  const handleSelectedDateValue = (e) => {
    setSelectedDateValue(e.target.value);
  };

  const handleDeathDateChange = (e) => {
    setDeathDate(e.target.value);
  };
  const data = getLocalState("@user");
  return (
    <>
      {data.IsSubscriped === 1 ? (
        <>
          <div style={{ background: "#fff", padding: "1.5rem" }}>
            {/* begin::Head */}
            <div className="text-center mb-10 mb-lg-20">
              <h3 className="font-size-h1">Wysh Scheduling</h3>
            </div>
            {/* end::Head */}

            {/*begin::Form*/}
            <form
              onSubmit={formik.handleSubmit}
              className="form fv-plugins-bootstrap fv-plugins-framework"
            >
              {/* {login?.error ? (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {login.error.errors}
                </div>
              </div>
            ) : null} */}

              <div className="form-group fv-plugins-icon-container">
                <select
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "beneficiary"
                  )}`}
                  name="beneficiary"
                  onChange={(e) => handleBeneficiaryChange(e)}
                >
                  <option value="">Select Beneficiary</option>
                  {beneficiaries?.data?.entityList?.map((beneficiary) => {
                    return (
                      <option
                        key={beneficiary.Identifier}
                        value={beneficiary.Identifier}
                      >
                        {beneficiary.FirstName} {beneficiary.LastName}
                      </option>
                    );
                  })}
                </select>
                {formik.touched.beneficiary && formik.errors.beneficiary ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.beneficiary}
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="form-group fv-plugins-icon-container">
                <input
                  placeholder="Subject"
                  type="text"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "subject"
                  )}`}
                  name="subject"
                  {...formik.getFieldProps("subject")}
                />
                {formik.touched.subject && formik.errors.subject ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.subject}</div>
                  </div>
                ) : null}
              </div>

              <CardEditor handleEditorChange={handleEditorChange} />

              <div className="form-group d-flex my-5">
                <label className="radio mr-5">
                  <input
                    type="radio"
                    name="selectDate"
                    value={"specificDate"}
                    checked={selectedDateValue === "specificDate"}
                    onChange={(e) => handleSelectedDateValue(e)}
                    className="mx-3 form-control form-control-solid"
                  />
                  Specific Date{" "}
                  <span style={{ border: "1px solid #bbb", marginLeft: 10 }} />
                </label>

                <label className="radio mx-5">
                  <input
                    type="radio"
                    name="selectDate"
                    value={"deathDate"}
                    checked={selectedDateValue === "deathDate"}
                    onChange={(e) => handleSelectedDateValue(e)}
                    className="mx-3 form-control form-control-solid"
                  />
                  After My Passing{" "}
                  <span style={{ border: "1px solid #bbb", marginLeft: 10 }} />
                </label>
              </div>
              <span>
                When selecting “Specific Date” option for your card, the card
                will be released on the selected date after the Next of Kin
                (NoK) confirms your passing. When selecting “After My Passing”
                option for your card, the card will be released immediately
                after the Next of Kin (NoK) confirms your passing.
              </span>

              {selectedDateValue === "specificDate" ? (
                <div className="form-group fv-plugins-icon-container">
                  <input
                    placeholder="Select Date"
                    type="date"
                    className={`form-control form-control-solid h-auto py-5 ${getInputClasses(
                      "specificDate"
                    )}`}
                    name="specificDate"
                    {...formik.getFieldProps("specificDate")}
                  />
                  {formik.touched.specificDate && formik.errors.specificDate ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.specificDate}
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : selectedDateValue === "deathDate" ? (
                <div className="form-group fv-plugins-icon-container">
                  <select
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "deathDate"
                    )}`}
                    name="deathDate"
                    onChange={(e) => handleDeathDateChange(e)}
                  >
                    <option value="">Select</option>
                    {afterDeathValues?.map((values) => {
                      return (
                        <option key={values.id} value={values.id}>
                          {values.value}
                        </option>
                      );
                    })}
                  </select>
                  {formik.touched.deathDate && formik.errors.deathDate ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.deathDate}
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}

              <div className="d-flex justify-content-around">
                <div className="mx-5 my-5 py-2 text-muted">
                  {previewItem ? (
                    <a
                      href={previewItem.realPath}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {previewItem.title}
                    </a>
                  ) : (
                    <p></p>
                  )}
                </div>

                <MediaLibrary from="card" setPreviewItem={setPreviewItem} />
              </div>

              <div className="form-group d-flex">
                <button
                  name="card"
                  type="submit"
                  disabled={formik.isSubmitting}
                  onClick={() => setIsPreview(false)}
                  className={`btn btn-primary font-weight-bold px-9 py-4 my-5`}
                  style={{ background: "#a1cf5f", border: "1px solid #a1cf5f" }}
                >
                  <span>Send</span>
                </button>

                <button
                  type="submit"
                  disabled={
                    !previewItem.realPath ||
                    !selectedDateValue ||
                    !selectedBeneficiary ||
                    !editorContent
                  }
                  onClick={() => setIsPreview(true)}
                  className={`btn btn-primary font-weight-bold px-9 py-4 my-5 mx-5`}
                  style={{ background: "#ff6600", border: "1px solid #ff6600" }}
                >
                  <span>Preview</span>
                </button>
              </div>
            </form>
          </div>
          <CardPreview
            show={show}
            handleClose={handleClose}
            cardData={sendCard}
          />
        </>
      ) : (
        <>
          <p>Please subscribe to unlock all features</p>
          <Link
            to="/plans"
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
            style={{
              background: "#ff6600",
              border: "1px solid #ff6600",
            }}
          >
            <span>Subscribe</span>
          </Link>
        </>
      )}
    </>
  );
};

export default injectIntl(Cards);
