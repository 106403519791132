import toast from "react-hot-toast";
import { takeLatest, call, put } from "redux-saga/effects";
import axios from "../../../API/axiosClient";
import {
  START_ADD_MEDIA_REQUEST,
  ADD_MEDIA_REQUEST_SUCCESS,
  ADD_MEDIA_REQUEST_FAILED,
  START_GET_MEDIA_REQUEST,
} from "../../actions/actionTypes";

export function* addMediaWatcher() {
  yield takeLatest(START_ADD_MEDIA_REQUEST, addMediaWorker);
}

function addMedia(data) {
  return axios.post("api/uploads", data);
}

function* addMediaWorker(action) {
  try {
    toast.loading("Saving your media...", { duration: 1750 });
    const response = yield call(addMedia, action.payload);
    const data = response.data;

    yield put({ type: ADD_MEDIA_REQUEST_SUCCESS, data });

    if (data?.code === 201) {
      toast.success("Media saved");
      yield put({ type: START_GET_MEDIA_REQUEST });
    } else {
      toast.success("Cannot save media");
    }
  } catch (error) {
    toast.success("Cannot save media");
    yield put({ type: ADD_MEDIA_REQUEST_FAILED, error });
    // swal("Beneficiary!", `${error?.response?.data?.errorMsg}`, "warning");
  }
}
