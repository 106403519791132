/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';

export function HeaderMenu({ layoutProps }) {
  return (
    <div
      id='kt_header_menu'
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            "/dashboard"
          )}`}
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="menu-text">Dashboard</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li> */}
        {/*end::1 Level*/}

        {/*Classic submenu*/}
        {/*begin::1 Level*/}
        {/* <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
            "/google-material"
          )}`}
        >
          <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="menu-text">Material UI</span>
            <i className="menu-arrow"></i>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}

        {/*Mega submenu*/}
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
            "/react-bootstrap"
          )}`}
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
        >
          <NavLink className="menu-link menu-toggle" to="/react-bootstrap">
            <span className="menu-text">Bootstrap</span>
            <i className="menu-arrow"></i>
          </NavLink>
        </li> */}

        {/*Classic submenu*/}
        {/*begin::1 Level*/}

        {/*end::1 Level*/}
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
}
