import React from "react";
import { Modal } from "react-bootstrap";

const CardPreview = (props) => {
  const { show, handleClose, cardData } = props;
  return (
    <Modal show={show} onHide={handleClose}>
      <div>
        {cardData?.data ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div dangerouslySetInnerHTML={{ __html: cardData?.data }} />{" "}
            <div
              style={{
                background: "white",
                width: 650,
                position: "absolute",
                right: "calc((100vw - (265px)) / 2)px",
              }}
            >
              <button
                style={{
                  background: "white",
                  color: "rgb(161, 207, 95)",
                  border: 0,
                }}
                id="kt_login_forgot_submit"
                className="btn btn-default icon-btn font-weight-bold "
                onClick={handleClose}
              >
                X Close
              </button>
            </div>
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </Modal>
  );
};

export default CardPreview;
