import React, { useState } from "react";
import { useFormik } from "formik";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import { getLocalState } from "../../../../helpers/localStorage";
import { useDispatch } from "react-redux";
import { resetPasswordAction } from "../../../../redux/actions/Auth/resetPassword";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import toast from "react-hot-toast";

const initialValues = {
  password: "",
  confirmpassword: "",
};

function ResetPassword(props) {
  const params = useParams();
  const { intl } = props;
  const dispatch = useDispatch();
  const [isRequested, setIsRequested] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();

  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    confirmpassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ResetPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);

      const formData = new FormData();

      formData.append("Email", params.email);
      formData.append("Password", values.password);
      formData.append("PasswordConfirmation", values.confirmpassword);

      dispatch(resetPasswordAction(formData));

      setTimeout(() => {
        setSubmitting(false);
        const data = getLocalState("@resetPassword");

        if (data?.code === 200) {
          toast.success("Password changed successfully! 👍");
          history.push("/auth/login");
        }
      }, 1500);
    },
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Reset Password</h3>
            <div className="text-muted font-weight-bold">
              Please Fill your new password
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}

            {/* begin: Password */}
            <div className="input-group fv-plugins-icon-container my-5">
              <input
                placeholder="Password"
                type={showPassword === true ? "text" : "password"}
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "password"
                )}`}
                name="password"
                {...formik.getFieldProps("password")}
              />
              <div class="input-group-prepend">
                <span
                  class="input-group-text"
                  style={{ border: "none" }}
                  onClick={() =>
                    setShowPassword(showPassword === true ? false : true)
                  }
                >
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Hidden.svg")}
                  />
                </span>
              </div>
            </div>
            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            ) : null}
            {/* end: Password */}

            <div className="input-group fv-plugins-icon-container my-5">
              <input
                placeholder="Confirm Password"
                type={showPassword === true ? "text" : "password"}
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "confirmpassword"
                )}`}
                name="confirmpassword"
                {...formik.getFieldProps("confirmpassword")}
              />
              <div class="input-group-prepend">
                <span
                  class="input-group-text"
                  style={{ border: "none" }}
                  onClick={() =>
                    setShowPassword(showPassword === true ? false : true)
                  }
                >
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Hidden.svg")}
                  />
                </span>
              </div>
            </div>
            {formik.touched.confirmpassword && formik.errors.confirmpassword ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.confirmpassword}
                </div>
              </div>
            ) : null}
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                style={{ background: "#a1cf5f", border: "1px solid #a1cf5f" }}
                disabled={formik.isSubmitting}
              >
                Submit
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(ResetPassword);
