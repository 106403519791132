import { takeLatest, call, put } from "redux-saga/effects";
import axios from "../../../API/axiosClient";
import {
  START_BENEFECIARIES_REQUEST,
  BENEFECIARIES_REQUEST_SUCCESS,
  BENEFECIARIES_REQUEST_FAILED,
} from "../../actions/actionTypes";

export function* beneficiaryWatcher() {
  yield takeLatest(START_BENEFECIARIES_REQUEST, beneficiaryWorker);
}

function beneficiaries() {
  return axios.get("api/beneficiaries");
}

function* beneficiaryWorker() {
  try {
    const response = yield call(beneficiaries);
    const data = response.data;

    yield put({ type: BENEFECIARIES_REQUEST_SUCCESS, data });
  } catch (error) {
    yield put({ type: BENEFECIARIES_REQUEST_FAILED, error });
  }
}
