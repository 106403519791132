import React from "react";
import VideoRecorder from "react-video-recorder";
import { useDispatch } from "react-redux";
import { addMediaAction } from "../../../redux/actions/Media/addMedia";
import swal from "sweetalert";

const VideoRecorderPage = () => {
  const dispatch = useDispatch();

  return (
    <VideoRecorder
      onRecordingComplete={(videoBlob) => {
        const formData = new FormData();
        formData.append("file", videoBlob);
        formData.append("type", videoBlob.type);

        dispatch(addMediaAction(formData));
      }}
    />
  );
};

export default VideoRecorderPage;
