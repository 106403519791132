import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhotoVideo,
  faCalendarAlt,
  faSmile,
  faUserFriends,
} from '@fortawesome/free-solid-svg-icons';
import { ActionNeeded } from './actionNeeded';
import { Link } from 'react-router-dom';
import { getSubscribtionInfoAction } from '../../redux/actions/getSubscribtionInfo';
import { getnNOKUsersAction } from '../../redux/actions/getNOKUsers';

import swal from 'sweetalert';
import { getLocalState, setLocalState } from '../../helpers/localStorage';
import AppleLogo from '../../_metronic/_assets/images/app-store.png';
import GoogleLogo from '../../_metronic/_assets/images/google-play.png';
import { NOKUsersTable } from './NOKtable';
import './dashboard-page.scss';

export function DashboardPage() {
  const dispatch = useDispatch();
  const subscribtionInfo = useSelector((state) => state.subscribtionInfo);
  const nokUsers = useSelector((state) => state.NOKUsers);

  useEffect(() => {
    dispatch(getSubscribtionInfoAction());
    dispatch(getnNOKUsersAction());
  }, []);

  useEffect(() => {
    const IsSubscriped = getLocalState('@IsSubscriped');
    if (IsSubscriped?.viewed === true) {
      return;
    } else {
      getSubscribtionInfo();
    }
  }, [subscribtionInfo]);

  function getSubscribtionInfo() {
    if (
      subscribtionInfo?.data?.entity?.IsSubscriped === 0 &&
      subscribtionInfo?.data?.entity?.IsNextOfKin === 0
    ) {
      swal({
        title: 'You are not subscribed to any package yet!!',
        text: 'Subscribe now and unlock all features',
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Cancel',
            value: null,
            visible: true,
            className: 'cancel_button',
            closeModal: true,
          },
          confirm: {
            text: 'Take me to plans page',
            value: true,
            visible: true,
            className: 'confirm_button',
            closeModal: true,
          },
        },
      }).then((ok) => {
        setLocalState('@IsSubscriped', { viewed: true });
        if (ok) {
          window.location.pathname = '/plans';
        } else {
          window.location.reload();
        }
      });
    }
  }

  const DashboardCard = ({ icon, title, dataInfo, footerInfo }) => (
    <div className='dashboard-card'>
      <div className='dashboard-card__body'>
        <div className='d-flex'>
          <FontAwesomeIcon
            icon={icon}
            size='3x'
            className='mr-5'
            style={{ color: '#ff6600' }}
          />
        </div>
        <div className='dashboard-card__body__info'>
          <h3 className='dashboard-card__body__info__indicator'>{title}</h3>
          <h3 className='dashboard-card__body__info__status'>{dataInfo}</h3>
        </div>
      </div>
      <div className='dashboard-card__footer'>{footerInfo}</div>
    </div>
  );

  return (
    <>
      {subscribtionInfo?.data?.entity?.IsNextOfKin === 1 ? (
        <div role='alert' class='fade alert alert-primary show'>
          <h5>
            You are a Next of kin account , you are responsible for confirming
            deceased for you users by clicking in the button above
          </h5>
        </div>
      ) : null}
      <div className='row '>
        <div className='col-lg-3 col-sm-6'>
          <DashboardCard
            title='Member Since'
            icon={faSmile}
            dataInfo={subscribtionInfo?.data?.entity?.MemberSinceDays}
            footerInfo={subscribtionInfo?.data?.entity?.MemberSince}
          />
        </div>
        <div className='col-lg-3 col-sm-6'>
          <DashboardCard
            title='Media Library'
            icon={faPhotoVideo}
            dataInfo={
              subscribtionInfo?.data?.entity?.IsSubscriped === 1
                ? subscribtionInfo?.data?.entity?.Subscription?.TotalFiles
                : 0
            }
            footerInfo='Since Account Creation'
          />
        </div>

        <div className='col-lg-3 col-sm-6'>
          <DashboardCard
            title='Scheduled Wyshes'
            icon={faCalendarAlt}
            dataInfo={
              subscribtionInfo?.data?.entity?.IsSubscriped === 1
                ? subscribtionInfo?.data?.entity?.Subscription?.TotalSentEcards
                : 0
            }
            footerInfo='Since Account Creation'
          />
        </div>

        <div className='col-lg-3 col-sm-6'>
          <DashboardCard
            title='Total Beneficiaries'
            icon={faUserFriends}
            dataInfo={subscribtionInfo?.data?.entity?.TotalBeneficiaries}
            footerInfo='Since Account Creation'
          />
        </div>
      </div>
      <div className='row my-4'>
        <div className='col-lg-6 col-sm-6'>
          <Card
            style={{
              background: '#a1cf5f',
              color: '#fff',
              border: '1px solid #a1cf5f',
            }}
          >
            <Card.Body>
              {subscribtionInfo?.data?.entity?.IsSubscriped === 1 ? (
                <>
                  <Card.Title>Your Package</Card.Title>
                  <h1>
                    {subscribtionInfo?.data?.entity?.Subscription?.PackageName}
                  </h1>

                  <Card.Text>
                    Renew in{' '}
                    {
                      subscribtionInfo?.data?.entity?.Subscription
                        ?.NextRenewalDateInDays
                    }{' '}
                    Days
                  </Card.Text>
                  <Link
                    to='/plans'
                    className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                    style={{
                      background: '#ff6600',
                      border: '1px solid #ff6600',
                    }}
                  >
                    <span>Upgrade plan</span>
                  </Link>
                </>
              ) : (
                <>
                  <Card.Title>
                    You don't have package yet , please subscribe
                  </Card.Title>
                  <Link
                    to='/plans'
                    className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                    style={{
                      background: '#ff6600',
                      border: '1px solid #ff6600',
                    }}
                  >
                    <span>Subscribe</span>
                  </Link>
                </>
              )}
            </Card.Body>
          </Card>
        </div>
        {subscribtionInfo?.data?.entity?.IsNextOfKin === 0 ? (
          <div className='col-6'>{<ActionNeeded />}</div>
        ) : (
          <div className='col-6'>
            {<NOKUsersTable nokUsers={nokUsers.data} />}
          </div>
        )}
      </div>
      <div className='row'>
        <div className='offset-md-8 col-4'>
          <img className='pull-left' src={AppleLogo} alt='' />
          <img className='pull-left' src={GoogleLogo} alt='' />
        </div>
      </div>
    </>
  );
}
