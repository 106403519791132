import { takeLatest, call, put } from "redux-saga/effects";
import axios from "../../API/axiosClient";
import {
  START_PAYMENT_STATUS_REQUEST,
  PAYMENT_STATUS_REQUEST_SUCCESS,
  PAYMENT_STATUS_REQUEST_FAILED,
} from "../actions/actionTypes";

export function* paymentStatusWatcher() {
  yield takeLatest(START_PAYMENT_STATUS_REQUEST, paymentStatusWorker);
}

function paymentStatus(payload) {
  return axios.post("api/payment-status", payload);
}

function* paymentStatusWorker(action) {
  try {
    const response = yield call(paymentStatus, action.payload);
    const data = response.data;

    yield put({ type: PAYMENT_STATUS_REQUEST_SUCCESS, data });
  } catch (error) {
    yield put({ type: PAYMENT_STATUS_REQUEST_FAILED, error });
  }
}
