import { takeLatest, call, put } from "redux-saga/effects";
import axios from "../../../API/axiosClient";
import { setLocalState } from "../../../helpers/localStorage";
import {
  START_RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_REQUEST_SUCCESS,
  RESET_PASSWORD_REQUEST_FAILED,
} from "../../actions/actionTypes";

export function* resetPasswordWatcher() {
  yield takeLatest(START_RESET_PASSWORD_REQUEST, resetPasswordWorker);
}

function resetPassword(data) {
  return axios.post("api/reset-password", data);
}

function* resetPasswordWorker(action) {
  try {
    const response = yield call(resetPassword, action.payload);
    const data = response.data;

    setLocalState("@resetPassword", data);

    yield put({ type: RESET_PASSWORD_REQUEST_SUCCESS, data });
  } catch (error) {
    yield put({
      type: RESET_PASSWORD_REQUEST_FAILED,
      error: error?.response?.data,
    });
  }
}
