import { takeLatest, call, put } from "redux-saga/effects";
import axios from "../../../API/axiosClient";
import { setLocalState } from "../../../helpers/localStorage";
import toast from "react-hot-toast";
import {
  CONFIRM_FORGETTOKEN_REQUEST_FAILED,
  CONFIRM_FORGETTOKEN_REQUEST_SUCCESS,
  START_CONFIRM_FORGETTOKEN_REQUEST,
} from "../../actions/actionTypes";

export function* confirmForgetTokenWatcher() {
  yield takeLatest(START_CONFIRM_FORGETTOKEN_REQUEST, confirmForgetTokenWorker);
}

function confirmForgetToken(data) {
  return axios.post("api/confirm-forget-token", data);
}

function* confirmForgetTokenWorker(action) {
  try {
    const response = yield call(confirmForgetToken, action.payload);
    const data = response.data;

    setLocalState("@forgetTokenConfirmed", data);

    yield put({ type: CONFIRM_FORGETTOKEN_REQUEST_SUCCESS, data });
  } catch (error) {
    toast.error("Invalid confirmation code");
    yield put({
      type: CONFIRM_FORGETTOKEN_REQUEST_FAILED,
      error: error?.response?.data,
    });
  }
}
