// imports the React Javascript Library
import React, { useState } from 'react';
import { Fab, withStyles } from '@material-ui/core';
import { CameraAltOutlined as EditIcon } from '@material-ui/icons';

const styles = () => ({
  root: {
    position: 'relative',
    borderRadius: 50,
    marginBottom: 20,
  },
  input: {
    display: 'none',
  },
  media: {
    width: '100px',
    height: '100px',
    borderRadius: 50,
  },
  editFab: {
    position: 'absolute',
    top: 55,
    right: 95,
    margin: 10,
    width: '35px',
    height: '35px',
  },
});

const ImageUploadCard = ({
  classes,
  initialImage,
  onFileSelectError,
  onFileSelectSuccess,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleUploadClick = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    if (file.size > 1024 * 1000)
      onFileSelectError({ error: 'File size cannot exceed more than 1MB' });
    else onFileSelectSuccess(file);

    reader.onloadend = function(e) {
      setSelectedFile([reader.result]);
    };
    setSelectedFile(event.target.files[0]);
  };

  return (
    <div className={classes.root}>
      <img
        width='100%'
        className={classes.media}
        alt='alt'
        src={selectedFile || initialImage}
      />
      <input
        accept='image/*'
        className={classes.input}
        id='contained-button-file'
        multiple
        type='file'
        onChange={handleUploadClick}
      />
      <label htmlFor='contained-button-file'>
        <Fab className={classes.editFab} component='span'>
          <EditIcon />
        </Fab>
      </label>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(ImageUploadCard);
