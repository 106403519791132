/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect, useState } from 'react';
import objectPath from 'object-path';
import { useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from '../../_core/MetronicSubheader';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';

const initialValues = {
  deathDate: '',
  deathPlace: '',
  NOK: '',
};

export function SubHeader(props) {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
  const [show, setShow] = useState(false);
  const [NOK, setNOK] = useState('');
  const [dataToConfirmWith, setDataToConfirmWith] = useState(null);

  const handleClose = () => setShow(false);

  const handleNOKChange = (e) => {
    setNOK(e.target.value);
  };

  const NOKList = useSelector((state) => state.NOKUsers?.data?.entityList);

  const SubHeaderSchema = Yup.object().shape({
    deathDate: Yup.string().required('required'),
    deathPlace: Yup.string().required('required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: SubHeaderSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      const formData = new FormData();

      formData.append('DeathDate', values.deathDate);
      formData.append('DeathPlace', values.deathPlace);
      formData.append('user_id', NOK);
      setDataToConfirmWith(formData);
    },
  });

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        'subheader.mobile-toggle'
      ),
      subheaderCssClasses: uiService.getClasses('subheader', true),
      subheaderContainerCssClasses: uiService.getClasses(
        'subheader_container',
        true
      ),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle('kt_aside_menu', location.pathname);
    const header = getBreadcrumbsAndTitle('kt_header_menu', location.pathname);
    const breadcrumbs =
      aside && aside.breadcrumbs.length > 0
        ? aside.breadcrumbs
        : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(
      aside && aside.title && aside.title.length > 0
        ? aside.title
        : header.title
    );
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  return (
    <div
      id='kt_subheader'
      className={`subheader py-2 py-lg-4   ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        {/* Info */}
        <div className='d-flex align-items-center flex-wrap mr-1'>
          {layoutProps.subheaderMobileToggle && (
            <button
              className='burger-icon burger-icon-left mr-4 d-inline-block d-lg-none'
              id='kt_subheader_mobile_toggle'
            >
              <span />
            </button>
          )}

          <div className='d-flex align-items-baseline mr-5'>
            <h5 className='text-dark font-weight-bold my-2 mr-5'>
              {subheader.title}
            </h5>
          </div>
        </div>

        {/* Toolbar */}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <form
            onSubmit={formik.handleSubmit}
            className='form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp'
          >
            {formik.status && (
              <div className='mb-10 alert alert-custom alert-light-danger alert-dismissible'>
                <div className='alert-text font-weight-bold'>
                  {formik.status}
                </div>
              </div>
            )}
            <div className='form-group fv-plugins-icon-container'>
              <input
                type='date'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'deathDate'
                )}`}
                name='deathDate'
                {...formik.getFieldProps('deathDate')}
              />
              {formik.touched.deathDate && formik.errors.deathDate ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.deathDate}</div>
                </div>
              ) : null}
            </div>
            <div className='form-group fv-plugins-icon-container'>
              <input
                type='text'
                placeholder='Death place'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'deathPlace'
                )}`}
                name='deathPlace'
                {...formik.getFieldProps('deathPlace')}
              />
              {formik.touched.deathPlace && formik.errors.deathPlace ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    {formik.errors.deathPlace}
                  </div>
                </div>
              ) : null}
            </div>
            <div className='form-group fv-plugins-icon-container'>
              <select
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'NOK'
                )}`}
                name='NOK'
                onChange={(e) => handleNOKChange(e)}
              >
                <option value=''>Select User</option>
                {NOKList?.map((NOK) => {
                  return (
                    <option key={NOK.Identifier} value={NOK.Identifier}>
                      {NOK.Name}
                    </option>
                  );
                })}
              </select>
              {formik.touched.NOK && formik.errors.NOK ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.NOK}</div>
                </div>
              ) : null}
            </div>
            <div className='form-group d-flex flex-wrap flex-center'>
              <button
                id='kt_login_forgot_submit'
                type='submit'
                className='btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4'
                disabled={formik.isSubmitting}
              >
                Confirm
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
