/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { useHtmlClassService } from "../../_metronic/layout";
import { KTUtil } from "../../_metronic/_assets/js/components/util";
import { useSelector } from "react-redux";

export function ActionNeeded({ className }) {
  const uiService = useHtmlClassService();
  const subscribtionInfo = useSelector((state) => state.subscribtionInfo);

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray100: objectPath.get(
        uiService.config,
        "js.colors.gray.gray100"
      ),
      colorsGrayGray700: objectPath.get(
        uiService.config,
        "js.colors.gray.gray700"
      ),
      colorsThemeBaseSuccess: "#ff6600",
      colorsThemeLightSuccess: "#d8f1b5",

      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

  useEffect(() => {
    const element = document.getElementById("chart_dashboard");
    if (!element) {
      return;
    } else {
      const height = parseInt(KTUtil.css(element, "height"));
      const options = getChartOptions(layoutProps, height, subscribtionInfo);

      const chart = new ApexCharts(element, options);

      chart.render();
      return function cleanUp() {
        chart.destroy();
      };
    }
  }, [layoutProps, subscribtionInfo]);

  return (
    <div className={`card card-custom ${className}`}>
      {/* Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title font-weight-bolder ">Scheduled Wyshes</h3>
        <div className="card-toolbar"></div>
      </div>
      {/* Body */}
      <div className="card-body d-flex flex-column">
        <div className="flex-grow-1">
          <div id="chart_dashboard" style={{ height: "200px" }}></div>
        </div>
        {/* <div className="pt-5">
          <p className="text-center font-weight-normal font-size-lg pb-7">
            Notes: Current sprint requires stakeholders
            <br />
            to approve newly amended policies
          </p>
          <a
            href="#"
            className="btn btn-success btn-shadow-hover font-weight-bolder w-100 py-3"
          >
            Generate Report
          </a>
        </div> */}
      </div>
    </div>
  );
}

function getChartOptions(layoutProps, height, subscribtionInfo) {
  const options = {
    series: [
      subscribtionInfo?.data?.entity?.Subscription
        ? [subscribtionInfo?.data?.entity?.Subscription?.TotalEcardsPercentage]
        : [0],
    ],
    chart: {
      height: height,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "65%",
        },
        dataLabels: {
          showOn: "always",
          name: {
            show: false,
            fontWeight: "700",
          },
          value: {
            color: layoutProps.colorsGrayGray700,
            fontSize: "30px",
            fontWeight: "700",
            offsetY: 12,
            show: true,
          },
        },
        track: {
          background: layoutProps.colorsThemeLightSuccess,
          strokeWidth: "100%",
        },
      },
    },
    colors: [layoutProps.colorsThemeBaseSuccess],
    stroke: {
      lineCap: "round",
    },
    labels: ["Progress"],
  };
  return options;
}
