import { takeLatest, call, put } from "redux-saga/effects";
import axios from "../../API/axiosClient";
import {
  START_COUNTRIES_REQUEST,
  COUNTRIES_REQUEST_SUCCESS,
  COUNTRIES_REQUEST_FAILED,
} from "../actions/actionTypes";

export function* countryWatcher() {
  yield takeLatest(START_COUNTRIES_REQUEST, countryWorker);
}

function countries() {
  return axios.get("api/countries");
}

function* countryWorker() {
  try {
    const response = yield call(countries);
    const data = response.data;

    yield put({ type: COUNTRIES_REQUEST_SUCCESS, data });
  } catch (error) {
    yield put({ type: COUNTRIES_REQUEST_FAILED, error });
  }
}
