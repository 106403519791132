import React, { useEffect, useState } from "react";
import { Table, Button, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { CircularProgress } from "@material-ui/core";
import swal from "sweetalert";
import ReactTooltip from "react-tooltip";
import CardPreview from "../Cards/CardPreview";
import { listCardsAction } from "../../../redux/actions/listCards";
import { previewECardAction } from "../../../redux/actions/previewECard";
import { deleteECardAction } from "../../../redux/actions/deleteECard";

function HistoryList(props) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    dispatch(listCardsAction());
  }, []);

  const listCards = useSelector((state) => state.listCards);
  const previewECard = useSelector((state) => state.previewECard);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const handleViewClick = (id) => {
    handleShow();
    const formData = new FormData();

    formData.append("Identifier", id);

    dispatch(previewECardAction(formData));
  };

  const handleDeleteClick = (id) => {
    swal({
      title: "Are you sure?",
      text: "You want to delete this card!",
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancel",
          value: null,
          visible: true,
          className: "cancel_button",
          closeModal: true,
        },
        confirm: {
          text: "OK",
          value: true,
          visible: true,
          className: "confirm_button",
          closeModal: true,
        },
      },
    }).then((willDelete) => {
      if (willDelete) {
        const data = {
          Identifier: id,
        };
        dispatch(deleteECardAction(data));
      } else {
        return true;
      }
    });
  };

  return (
    <div className="row">
      {listCards?.data?.entityList?.length ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Beneficiary Name</th>
              <th>Beneficiary Email</th>
              <th>Sending Date</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {listCards?.data?.entityList?.map((card, index) => {
              return (
                <tr key={card.Identifier}>
                  <td>{index + 1}</td>
                  <td>{card.BeneficiaryName}</td>
                  <td>{card.BeneficiaryEmail}</td>
                  <td>{card.SendingDate}</td>
                  <td>
                    <div data-tip={card.Status.Title}>
                      <img src={card.Status.Icon}></img>
                    </div>
                    <ReactTooltip place="top" type="dark" effect="float">
                      <span>{card.Status.Title}</span>
                    </ReactTooltip>
                  </td>
                  <td>
                    <Button
                      className="btn btn-sm btn-success"
                      style={{
                        background: "#ff6600",
                        border: "1px solid #ff6600",
                      }}
                      onClick={() => handleViewClick(card.Identifier)}
                    >
                      View
                    </Button>
                    <Button
                      className="btn btn-sm btn-danger mx-3"
                      onClick={() => handleDeleteClick(card.Identifier)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div className="mx-5">
          {listCards?.fetching === true ? (
            <CircularProgress color="primary" />
          ) : (
            <h3>No History</h3>
          )}
        </div>
      )}
      <CardPreview
        show={show}
        handleClose={handleClose}
        cardData={previewECard}
      />
    </div>
  );
}

export default injectIntl(HistoryList);
