import React, { useState, useEffect } from 'react';
import { Table, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { getBeneficiariesAction } from '../../../redux/actions/Beneficiaries/getBeneficiaries';
import { deleteBeneficiaryAction } from '../../../redux/actions/Beneficiaries/deleteBeneficiary';
import { CircularProgress } from '@material-ui/core';
import swal from 'sweetalert';
import './beneficiaries-list.scss';

function BeneficiariesList(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [filterNOK, setfilterNOK] = useState(false);
  const [NOKList, setNOKList] = useState([]);

  const beneficiaries = useSelector((state) => state.beneficiaries);

  useEffect(() => {
    dispatch(getBeneficiariesAction());
  }, []);

  useEffect(() => {
    setNOKList(beneficiaries.data?.entityList);
  }, [beneficiaries]);

  useEffect(() => {
    if (filterNOK === true) {
      const result = NOKList?.filter((benef) => benef.IsNextOfKin === true);
      setNOKList(result);
    } else {
      const result = beneficiaries?.data?.entityList;
      setNOKList(result);
    }
  }, [filterNOK]);

  const handleAddClick = () => {
    history.push('/beneficiary-form');
  };

  const handleEditBeneficiary = (id) => {
    history.push(`/beneficiary/${id}`);
  };

  const handleFilterNOKChange = () => {
    setfilterNOK(!filterNOK);
  };

  const handleDeleteBeneficiary = (id) => {
    swal({
      title: 'Are you sure?',
      text: 'You want to delete this beneficiary!',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          value: null,
          visible: true,
          className: 'cancel_button',
          closeModal: true,
        },
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: 'confirm_button',
          closeModal: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteBeneficiaryAction(id));
      } else {
        return true;
      }
    });
  };

  return (
    <div className='beneficiaries-list'>
      <div className='beneficiaries-list__header'>
        <button
          className='beneficiaries-list__filter-button'
          onClick={() => handleAddClick()}
        >
          Add Beneficiary
        </button>
        <label className='checkbox'>
          <input
            type='checkbox'
            checked={filterNOK}
            onChange={() => handleFilterNOKChange()}
            className='mx-3 form-control form-control-solid'
          />
          Filter by next of kin
          <span style={{ border: '1px solid #3699FF', marginLeft: 10 }} />
        </label>
      </div>

      {NOKList?.length ? (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Relation</th>
                <th>Next Of Kin</th>
                <th>Added</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {NOKList?.map((beneficiary, index) => {
                return (
                  <tr
                    style={{ background: '#fff' }}
                    key={beneficiary.Identifier}
                  >
                    <td>{index + 1}</td>
                    <td>{beneficiary.FirstName}</td>
                    <td>{beneficiary.LastName}</td>
                    <td>{beneficiary.Email}</td>
                    <td>{beneficiary.Relation}</td>
                    <td
                      style={{
                        color: beneficiary.IsNextOfKin ? '#a1cf5f' : '',
                      }}
                    >
                      {beneficiary.IsNextOfKin ? 'Yes' : 'No'}
                    </td>
                    <td style={{ color: '#b0b0b0' }}>
                      {new Date().toLocaleDateString('en-US', {
                        month: 'long',
                        year: 'numeric',
                        day: 'numeric',
                      })}
                    </td>
                    <td>
                      <Button
                        className='btn btn-sm btn-success'
                        style={{
                          background: '#ff6600',
                          border: '1px solid #ff6600',
                        }}
                        onClick={() =>
                          handleEditBeneficiary(beneficiary.Identifier)
                        }
                      >
                        Edit
                      </Button>
                      <Button
                        className='btn btn-sm btn-danger mx-3'
                        onClick={() =>
                          handleDeleteBeneficiary(beneficiary.Identifier)
                        }
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      ) : (
        <div className='mx-5'>
          {beneficiaries?.fetching === true ? (
            <CircularProgress color='primary' />
          ) : (
            <div className='col col-lg-12 my-4'>
              <h3>No Beneficiaries</h3>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default injectIntl(BeneficiariesList);
