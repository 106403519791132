import React, { useState } from "react";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import { getLocalState } from "../../../../helpers/localStorage";
import { useDispatch, useSelector } from "react-redux";
import { confirmForgetTokenAction } from "../../../../redux/actions/Auth/confirmForgetToken";
import { CircularProgress } from "@material-ui/core";
import toast from "react-hot-toast";

function ConfirmForgetToken(props) {
  const { intl } = props;
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const confirmation = useSelector((state) => state.confirmForgetToken);

  React.useEffect(() => {
    const formData = new FormData();

    formData.append("Email", params.email);
    formData.append("ForgetToken", params.code);

    dispatch(confirmForgetTokenAction(formData));
  }, []);

  React.useEffect(() => {
    if (confirmation?.data?.code === 401) {
      toast.error(`${confirmation?.data?.errors}, Please try again.`);
      history.push("/auth/forgot-password");
    } else if (confirmation?.data?.code === 200) {
      toast.success(confirmation?.data?.message);
      history.push(`/auth/reset-password/${params.email}`);
    }
  }, [confirmation]);

  return (
    <>
      {
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Confirming your request...</h3>
            <br />
            <CircularProgress size={35} color="primary" />
          </div>
        </div>
      }
    </>
  );
}

export default injectIntl(ConfirmForgetToken);
