/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhotoVideo,
  faUserFriends,
  faCalendarAlt,
  faImage,
  faTachometerAlt,
} from '@fortawesome/free-solid-svg-icons';
import { AsideMenuItem } from '../aside-menu-item/AsideMenuItem';
import { checkIsActive } from '../../../../_helpers';

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          'menu-item-active'} menu-item-open menu-item-not-hightlighted`
      : '';
  };

  const items = [
    {
      title: 'Dashboard',
      icon: faTachometerAlt,
      link: '/dashboard',
    },
    {
      title: 'Beneficiaries',
      icon: faUserFriends,
      link: '/beneficiaries',
    },
    {
      title: 'Media Library',
      icon: faPhotoVideo,
      link: '/media/library',
    },
    {
      title: 'Wyshes',
      icon: faImage,
      link: '/cards',
    },
    {
      title: 'Scheduled',
      icon: faCalendarAlt,
      link: '/history',
    },
  ];

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {items.map(({ title, link, icon }) => {
          const active = getMenuItemActive(link, false);
          return (
            <AsideMenuItem
              active={active}
              title={title}
              icon={icon}
              link={link}
            />
          );
        })}
      </ul>
    </>
  );
}
