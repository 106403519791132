import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function AsideMenuItem({ active, icon, title, link }) {
  return (
    <li
      className={`menu-item ${active ? 'menu-item-active' : ''}`}
      aria-haspopup='true'
    >
      <NavLink className='menu-link' to={link}>
        <span className='svg-icon menu-icon'>
          <FontAwesomeIcon icon={icon} />
        </span>
        <span className='menu-text'>{title}</span>
      </NavLink>
    </li>
  );
}
