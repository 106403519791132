import { takeLatest, call, put } from "redux-saga/effects";
import axios from "../../API/axiosClient";
import { setLocalState } from "../../helpers/localStorage";
import {
  START_EDIT_PROFILE_REQUEST,
  EDIT_PROFILE_REQUEST_SUCCESS,
  EDIT_PROFILE_REQUEST_FAILED,
} from "../actions/actionTypes";
import swal from "sweetalert";

export function* editProfileWatcher() {
  yield takeLatest(START_EDIT_PROFILE_REQUEST, editProfileWorker);
}

function editProfile(payload) {
  return axios.post("api/update-profile", payload);
}

function* editProfileWorker(action) {
  try {
    const response = yield call(editProfile, action.payload);
    const data = response.data;

    if (data) {
      swal("Profile!", "Updated successfully!", "success").then(() => {
        setLocalState("@user", data.entity);
        window.location.reload();
      });
    }

    yield put({ type: EDIT_PROFILE_REQUEST_SUCCESS, data });
  } catch (error) {
    yield put({ type: EDIT_PROFILE_REQUEST_FAILED, error });
  }
}
