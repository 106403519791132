import React from "react";
import { injectIntl } from "react-intl";
import BeneficiariesList from "./BeneficiariesList";

function Beneficiaries() {
  return (
    <div className="p-5">
      <BeneficiariesList />
    </div>
  );
}

export default injectIntl(Beneficiaries);
