import { takeLatest, call, put } from "redux-saga/effects";
import axios from "../../API/axiosClient";
import {
  START_CARD_REQUEST,
  CARD_REQUEST_SUCCESS,
  CARD_REQUEST_FAILED,
} from "../actions/actionTypes";
import swal from "sweetalert";
import toast from "react-hot-toast";

export function* sendCardWatcher() {
  yield takeLatest(START_CARD_REQUEST, sendCardWorker);
}

function sendCard(payload) {
  return axios.post("api/send-ecard", payload);
}

function displayPackageExceedAlert() {
  swal({
    title: "You Exceed your package limit!",
    text: "Subscribe now and unlock new e-cards limits",
    icon: "warning",
    buttons: {
      cancel: {
        text: "Cancel",
        value: null,
        visible: true,
        className: "cancel_button",
        closeModal: true,
      },
      confirm: {
        text: "Take me to plans page",
        value: true,
        visible: true,
        className: "confirm_button",
        closeModal: true,
      },
    },
  }).then((ok) => {
    if (ok) {
      window.location.pathname = "/plans";
    }
  });
}

function* sendCardWorker(action) {
  try {
    const response = yield call(sendCard, action.payload);
    const data = response.data;

    if (data?.code === 200 && action.isPreview !== 1) {
      toast.success("Card scheduled successfully!");
    } else {
      if (data?.code === 508) {
        displayPackageExceedAlert();
      } else {
        toast.error(data?.errors ?? "Server connection error");
      }
    }

    yield put({ type: CARD_REQUEST_SUCCESS, data });
  } catch (error) {
    yield put({ type: CARD_REQUEST_FAILED, error });
  }
}
