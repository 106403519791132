import { takeLatest, call, put } from "redux-saga/effects";
import axios from "../../../API/axiosClient";
import {
  START_RELATIONS_REQUEST,
  RELATIONS_REQUEST_SUCCESS,
  RELATIONS_REQUEST_FAILED,
} from "../../actions/actionTypes";

export function* getRelationsWatcher() {
  yield takeLatest(START_RELATIONS_REQUEST, getRelationsWorker);
}

function relations() {
  return axios.get("api/relations");
}

function* getRelationsWorker() {
  try {
    const response = yield call(relations);
    const data = response.data;

    yield put({ type: RELATIONS_REQUEST_SUCCESS, data });
  } catch (error) {
    yield put({ type: RELATIONS_REQUEST_FAILED, error });
  }
}
