import { takeLatest, call, put } from "redux-saga/effects";
import axios from "../../API/axiosClient";
import {
  START_CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_REQUEST_SUCCESS,
  CHANGE_PASSWORD_REQUEST_FAILED,
} from "../actions/actionTypes";
import swal from "sweetalert";

export function* chnagePasswordWatcher() {
  yield takeLatest(START_CHANGE_PASSWORD_REQUEST, chnagePasswordWorker);
}

function chnagePassword(payload) {
  return axios.post("api/change-password", payload);
}

function* chnagePasswordWorker(action) {
  try {
    const response = yield call(chnagePassword, action.payload);
    const data = response.data;
    if (data?.code === 200) {
      swal("", "Your password has been changed successfully", "success");
    }

    yield put({ type: CHANGE_PASSWORD_REQUEST_SUCCESS, data });
  } catch (error) {
    yield put({ type: CHANGE_PASSWORD_REQUEST_FAILED, error });
  }
}
