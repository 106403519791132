import { takeLatest, call, put } from "redux-saga/effects";
import axios from "../../API/axiosClient";
import {
  START_PACKAGES_REQUEST,
  PACKAGES_REQUEST_SUCCESS,
  PACKAGES_REQUEST_FAILED,
} from "../actions/actionTypes";

export function* packagesWatcher() {
  yield takeLatest(START_PACKAGES_REQUEST, packagesWorker);
}

function packages() {
  return axios.get("api/packages");
}

function* packagesWorker() {
  try {
    const response = yield call(packages);
    const data = response.data;

    yield put({ type: PACKAGES_REQUEST_SUCCESS, data });
  } catch (error) {
    yield put({ type: PACKAGES_REQUEST_FAILED, error });
  }
}
