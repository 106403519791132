import React, { useState } from "react";
import { useFormik } from "formik";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import { getLocalState } from "../../../../helpers/localStorage";
import { useDispatch } from "react-redux";
import { mailConfirmationAction } from "../../../../redux/actions/Auth/mailConfirmation";
import { CircularProgress } from "@material-ui/core";
import toast from "react-hot-toast";

function EmailActivation(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  React.useEffect(() => {
    const formData = new FormData();

    formData.append("Email", params.email);
    formData.append("ConfirmRegisterToken", params.code);

    dispatch(mailConfirmationAction(formData));

    setTimeout(() => {
      const data = getLocalState("@isConfirmed");
      if (data?.entity?.Status?.toLowerCase() === "active") {
        window.location.pathname = "/dashboard";
      } else {
        toast.error("Email already verified, try to login");
        history.push("/auth/login");
      }
    }, 1500);
  }, []);

  return (
    <>
      {
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Verifying your email...</h3>
            <br />
            <CircularProgress size={35} color="primary" />
          </div>
        </div>
      }
    </>
  );
}

export default injectIntl(EmailActivation);
