import {
  START_MAIL_CONFIRMATION_REQUEST,
  MAIL_CONFIRMATION_REQUEST_FAILED,
  MAIL_CONFIRMATION_REQUEST_SUCCESS,
} from "../../actions/actionTypes";

const initialState = {
  fetching: false,
  data: null,
  error: null,
};

export function mailConfirmationReducer(state = initialState, action) {
  switch (action.type) {
    case START_MAIL_CONFIRMATION_REQUEST:
      return { ...state, fetching: true, error: null };
    case MAIL_CONFIRMATION_REQUEST_SUCCESS:
      return { ...state, fetching: false, data: action.data };
    case MAIL_CONFIRMATION_REQUEST_FAILED:
      return { ...state, fetching: false, data: null, error: action.error };
    default:
      return state;
  }
}
