import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { getCountriesAction } from '../../../redux/actions/getCountries';
import { getCountryCitiesAction } from '../../../redux/actions/getCountryCities';
import { editProfileAction } from '../../../redux/actions/editProfile';
import { getLocalState } from '../../../helpers/localStorage';
import './user-profile-page.scss';

const userData = getLocalState('@user');

const initialValues = {
  firstname: userData?.FirstName,
  lastname: userData?.LastName,
  email: userData?.Email,
  phone: userData?.Phone,
  state: userData?.State,
  postalcode: userData?.PostalCode,
  street1: userData?.StreetOne,
  street2: userData?.StreetTwo,
};

function UserProfile(props) {
  const { intl } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');

  function getCities() {
    return userData
      ? dispatch(getCountryCitiesAction(userData.CountryID))
      : null;
  }

  useEffect(() => {
    dispatch(getCountriesAction());
    getCities();
  }, []);

  const countries = useSelector((state) => state.countries);
  const cities = useSelector((state) => state.cities);
  const register = useSelector((state) => state.register);

  const UserProfileSchema = Yup.object().shape({
    firstname: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    lastname: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    phone: Yup.string()
      .min(5, 'Minimum 3 numbers')
      .max(14, 'Maximum 14 number')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),

    postalcode: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    state: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    street1: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    street2: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    const id = e.target.value;

    if (id) {
      dispatch(getCountryCitiesAction(parseInt(id)));
    }
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: UserProfileSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setSubmitting(true);

      const formData = new FormData();

      formData.append('FirstName', values.firstname);
      formData.append('last_name', values.lastname);
      formData.append('phone', values.phone);
      formData.append('Email', values.email);
      formData.append('State', values.state);
      formData.append('PostalCode', values.postalcode);
      formData.append('StreetOne', values.street1);
      formData.append('StreetTwo', values.street2);
      formData.append(
        'CountryID',
        country ? parseInt(country) : userData.CountryID
      );
      formData.append('CityID', city ? parseInt(city) : userData.CityID);

      dispatch(editProfileAction(formData));

      setTimeout(() => {
        setSubmitting(false);
        disableLoading();
        const registerData = getLocalState('@register');

        if (registerData?.code === 200) {
          window.location.pathname = '/auth/activation';
        }
      }, 1500);
    },
  });

  return (
    <div className='user-profile-form-wrapper'>
      <form
        id='kt_login_signin_form'
        className='form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp'
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {register?.error && (
          <div className='mb-10 alert alert-custom alert-light-danger alert-dismissible'>
            <div className='alert-text font-weight-bold'>
              {register.error.errorMsg}
            </div>
          </div>
        )}
        {/* end: Alert */}

        {/* begin: firstname */}
        <div className='row'>
          <div className='col col-lg-6'>
            <div className='form-group fv-plugins-icon-container'>
              <label>First Name</label>
              <input
                placeholder='First name'
                type='text'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'firstname'
                )}`}
                name='firstname'
                {...formik.getFieldProps('firstname')}
              />
              {formik.touched.firstname && formik.errors.firstname ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.firstname}</div>
                </div>
              ) : null}
            </div>
          </div>
          <div className='col col-lg-6'>
            <div className='form-group fv-plugins-icon-container'>
              <label>Last name</label>
              <input
                placeholder='Last name'
                type='text'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'lastname'
                )}`}
                name='lastname'
                {...formik.getFieldProps('lastname')}
              />
              {formik.touched.lastname && formik.errors.lastname ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.lastname}</div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {/* end: Fullname */}

        <div className='row'>
          <div className='col col-lg-6'>
            <div className='form-group fv-plugins-icon-container'>
              <label>Email</label>
              <input
                placeholder='Email'
                type='email'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'email'
                )}`}
                name='email'
                {...formik.getFieldProps('email')}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
          </div>

          <div className='col col-lg-6'>
            <div className='form-group fv-plugins-icon-container'>
              <label>Phone</label>
              <input
                placeholder='Phone'
                type='phone'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'phone'
                )}`}
                name='phone'
                {...formik.getFieldProps('phone')}
              />
              {formik.touched.phone && formik.errors.phone ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.phone}</div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col col-lg-6'>
            <div className='form-group fv-plugins-icon-container'>
              <label>Country</label>
              <select
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'country'
                )}`}
                name='country'
                onChange={(e) => handleCountryChange(e)}
                value={country}
              >
                {userData ? (
                  <option value={userData?.CountryID}>
                    {userData?.Country}
                  </option>
                ) : (
                  <option value=''>Select Country</option>
                )}
                {countries?.data?.entityList?.map((country) => {
                  return (
                    <option key={country.Identifier} value={country.Identifier}>
                      {country.Name}
                    </option>
                  );
                })}
              </select>
              {formik.touched.country && formik.errors.country ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.country}</div>
                </div>
              ) : null}
            </div>
          </div>
          <div className='col col-lg-6'>
            <div className='form-group fv-plugins-icon-container'>
              <label>Region</label>
              <select
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'city'
                )}`}
                name='city'
                onChange={(e) => handleCityChange(e)}
              >
                {userData ? (
                  <option value={userData?.CityID}>{userData?.City}</option>
                ) : (
                  <option value=''>Select Region</option>
                )}
                {cities?.data?.entityList?.map((city) => {
                  return (
                    <option key={city.Identifier} value={city.Identifier}>
                      {city.Name}
                    </option>
                  );
                })}
              </select>
              {formik.touched.city && formik.errors.city ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.city}</div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col col-lg-6'>
            <div className='form-group fv-plugins-icon-container'>
              <label>Postal code</label>
              <input
                placeholder='Postal code'
                type='text'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'postalcode'
                )}`}
                name='postalcode'
                {...formik.getFieldProps('postalcode')}
              />
              {formik.touched.postalcode && formik.errors.postalcode ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    {formik.errors.postalcode}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className='col col-lg-6'>
            <div className='form-group fv-plugins-icon-container'>
              <label>State</label>
              <input
                placeholder='State'
                type='text'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'state'
                )}`}
                name='state'
                {...formik.getFieldProps('state')}
              />
              {formik.touched.state && formik.errors.state ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.state}</div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col col-lg-6'>
            <div className='form-group fv-plugins-icon-container'>
              <label>Street 1</label>
              <input
                placeholder='Street 1'
                type='text'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'street1'
                )}`}
                name='street1'
                {...formik.getFieldProps('street1')}
              />
              {formik.touched.street1 && formik.errors.street1 ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.street1}</div>
                </div>
              ) : null}
            </div>
          </div>
          <div className='col col-lg-6'>
            <div className='form-group fv-plugins-icon-container'>
              <label>Street 2</label>
              <input
                placeholder='Street 2'
                type='text'
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  'street2'
                )}`}
                name='street2'
                {...formik.getFieldProps('street2')}
              />
              {formik.touched.street2 && formik.errors.street2 ? (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.street2}</div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {/* end: Confirm Password */}

        <div className='form-group d-flex flex-wrap flex-center'>
          <button
            type='submit'
            disabled={formik.isSubmitting}
            className='edit-profile-form__button edit-profile-form__button--cancel'
          >
            <span>Update</span>
            {loading && <span className='ml-3 spinner spinner-white'></span>}
          </button>

          <Link to='/auth/login'>
            <button
              type='button'
              className='edit-profile-form__button edit-profile-form__button--edit'
            >
              Cancel
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(UserProfile);
