import React from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { stripPaymentStatusAction } from '../../../redux/actions/stripePaymentStatus';
import { useHistory } from 'react-router';
import './plans.scss';

const PaymentPage = (props) => {
  const { handleClose, paymentData } = props;
  //#region Local state
  const [loading, setLoading] = React.useState(false);
  //#endregion
  //#region hooks
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const history = useHistory();
  //#endregion

  //#region Transaction Success
  const handleSuccessPayment = () => {
    dispatch(
      stripPaymentStatusAction({
        client_secret: paymentData.data.entity.client_secret,
        status: 'success',
      })
    );
    setLoading(false);
    handleClose();
    toast.success('Successful payment! 👍');
    history.push('/dashboard');
  };
  //#endregion

  //#region Transaction Failure
  const handleFailure = (result, close = false) => {
    setLoading(false);
    toast.error(
      result?.error?.message ?? 'Cannot pay, please try again in minutes'
    );
    if (close) {
      handleClose();
    }
  };
  //#endregion

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      stripe
        .confirmCardPayment(paymentData.data.entity.client_secret, {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        })
        .then((result) => {
          // Fire the status to the backend and forget the result
          if (result.paymentIntent) {
            handleSuccessPayment();
          } else {
            handleFailure(result);
          }
        });
    } catch (e) {
      handleFailure(null, true);
    }
  };

  return paymentData.fetching ? (
    <Modal.Body className='d-flex justify-content-center align-items-center'>
      <div className={'spinner spinner-sm spinner-primary mb-5'}></div>
    </Modal.Body>
  ) : (
    <form onSubmit={handleSubmit}>
      <Modal.Body>
        <CardElement />
      </Modal.Body>
      <Modal.Footer>
        <button
          className='btn btn-primary font-weight-bold px-3 py-2 my-2'
          type='submit'
          disabled={!stripe || loading}
        >
          {loading ? 'Paying....' : 'Pay'}
        </button>
        <button
          className={`btn btn-default font-weight-bold px-3 py-2 my-2`}
          onClick={handleClose}
          type='button'
        >
          Close
        </button>
      </Modal.Footer>
    </form>
  );
};

export default PaymentPage;
