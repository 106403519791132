import { takeLatest, call, put } from "redux-saga/effects";
import axios from "../../../API/axiosClient";
import {
  START_GET_MEDIA_REQUEST,
  GET_MEDIA_REQUEST_SUCCESS,
  GET_MEDIA_REQUEST_FAILED,
} from "../../actions/actionTypes";

export function* getMediaWatcher() {
  yield takeLatest(START_GET_MEDIA_REQUEST, getMediaWorker);
}

function getMedia(mediaType) {
  return axios.get("api/uploads", { params: { Type: mediaType } });
}

function* getMediaWorker(action) {
  try {
    const response = yield call(getMedia, action.mediaType);
    const data = response.data;

    yield put({ type: GET_MEDIA_REQUEST_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_MEDIA_REQUEST_FAILED, error });
  }
}
