import {
  START_PAYMENT_STATUS_REQUEST,
  PAYMENT_STATUS_REQUEST_SUCCESS,
  PAYMENT_STATUS_REQUEST_FAILED,
} from "../actions/actionTypes";

const initialState = {
  fetching: false,
  data: null,
  error: null,
};

export function paymentStatusReducer(state = initialState, action) {
  switch (action.type) {
    case START_PAYMENT_STATUS_REQUEST:
      return { ...state, fetching: true, error: null };
    case PAYMENT_STATUS_REQUEST_SUCCESS:
      return { ...state, fetching: false, data: action.data };
    case PAYMENT_STATUS_REQUEST_FAILED:
      return { ...state, fetching: false, data: null, error: action.error };
    default:
      return state;
  }
}
