import React from "react";
import { Editor } from "@tinymce/tinymce-react";

const CardEditor = (props) => {
  const { handleEditorChange } = props;

  return (
    <Editor
      initialValue=""
      apiKey="j142ff6d55q0f3mwbasmgstaordlwmlsbic4ca5j19w56fga"
      init={{
        height: 350,
        menubar: false,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar: `undo redo | formatselect | bold italic backcolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help`,
      }}
      onEditorChange={handleEditorChange}
    />
  );
};

export default CardEditor;
