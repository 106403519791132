import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { getLocalState } from "../../../helpers/localStorage";
import MediaLibrary from "./MediaLibrary";
import VideoRecorder from "./VideoRecorder";
import { Link } from "react-router-dom";

const MediaPage = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const data = getLocalState("@user");

  return (
    <div className="row">
      <div className="col-lg-6 col-sm-12">
        {data.IsSubscriped === 1 ? (
          <>
            {" "}
            <MediaLibrary />
            <span className="px-5">OR</span>
            <button
              onClick={() => handleShow()}
              className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
            >
              <span>Record a video</span>
            </button>
          </>
        ) : (
          <>
            <p>Please subscribe to unlock all features</p>
              <Link
              to="/plans"
              className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
              style={{
                background: "#ff6600",
                border: "1px solid #ff6600",
              }}
            >
              <span>Subscribe</span>
            </Link>
          </>
        )}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Record a video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VideoRecorder />
        </Modal.Body>
        <Modal.Footer>
          <button
            className={`btn btn-primary font-weight-bold px-3 py-4 my-3`}
            onClick={handleClose}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MediaPage;
